/* =====================
    Feature Area 
========================*/



.bk-feature{
    .icon{
        margin-right: 20px; 
        i{
            font-size: 40px;
        }
    }
}















